/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap"); */

:root {
	--darkColor: rgb(3, 40, 54);
	--darkerColor: rgb(1, 23, 31);
	--yellow: rgb(244, 198, 80);
	--darkYellow: rgb(230, 183, 65);
	--pink: rgb(250, 126, 112);
	--blue: rgb(47, 113, 195);
	--lightBlue: rgb(0, 149, 226);
	--darkLightBlue: rgb(2, 130, 194);
	--grey: rgb(248, 248, 248);
	--darkGrey: rgb(237, 237, 237);
}

.app {
	max-width: 100vw; 	/* dont touch  */
	margin: 0 auto;  	/* dont touch  */
	overflow-x: auto
	

} 

html {
	scroll-behavior: smooth;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

/* body{
  overflow-y: auto !important;
  padding-right: 0 !important;
} */

.app {
	min-height: 75vh;
	margin-top: 0px;
	scroll-behavior: smooth;
	overflow-x: clip;
}

.page-content-wrap {
	flex: 1;
	min-height: 100vh;
	position: relative;
	/* padding-bottom: 268px; */
	/* padding-bottom: 320px; */
}

/* @media (max-width: 650px) {
  .page-content-wrap {
    padding-bottom: 332px;
  }
}

@media (max-width: 400px) {
  .page-content-wrap {
    padding-bottom: 422px;
  }
} */

.container {
	width: 80%;
	max-width: 1440px;
}

h1 {
	font-size: 3rem;
	color: var(--darkColor);
	font-family: "Roboto", sans-serif;
}

.flex {
	display: flex;
}

.column-center {
	flex-direction: column;
	align-items: center;
}

.row-center {
	justify-content: center;
}

.wrap {
	flex-wrap: wrap;
}

.page {
	padding-top: 4rem;
	width: 100%;
	overflow-x: hidden;
	min-height: 100vh;
	font-family: "Roboto", sans-serif;
}

.right-border {
	border-right: 1px solid #ededed;
}

.pb1 {
	padding-bottom: 1rem;
}

.pb2 {
	padding-bottom: 2rem;
}

/* Modals */
.closeButtonModalKino {
	position: absolute;
	right: 15px;
	top: 10px;
	background-color: transparent;
	outline: none;
	border: none;
	font-size: 2rem;
	cursor: pointer;
}

/* Material ui custom changes text */
.MuiTypography-subtitle1 {
	font-weight: 400 !important;
}

/* Material ui custom changes colors */
.MuiButton-containedPrimary {
	color: #fff !important;
	background-color: #032836 !important;
}
.MuiButton-contained.Mui-disabled {
	color: rgba(0, 0, 0, 0.26) !important;
	box-shadow: none !important;
	background-color: rgba(0, 0, 0, 0.12) !important;
}

/* Material ui custom changes modals */
.MuiDialogTitle-root {
	max-width: 500px !important;
	width: 100% !important;
}
.MuiDialog-paperWidthSm {
	width: 500px !important;
}
@media (max-width: 768px) {
	.app {
		overflow-x: clip;
	}
}

/* scrollbar changes */
::-webkit-scrollbar {
	height: 8px;
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(177, 177, 177, 0.8);
	border-radius: 10px;
}

/* remove the input type number arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Material UI custom change to buttons */
.MuiButton-root{
	letter-spacing: 0.05rem !important;
	/* text-transform: unset !important; */
	font-size: 0.9rem !important;
	border-radius: 6px !important;
}

.MuiTypography-root {
	letter-spacing: 0.05em !important;
}
 .MuiButton-root .MuiButton-label{
	line-height: 1.8 !important;
} 
.MuiButton-containedSizeLarge{
	border-radius: 18px !important;
	padding: 18px 24px !important;
}

@media (max-width: 360px) {
	.appstore-laptop-responsive {
		width: 100% !important;
		height: 150px !important;
		
	}

	.appstore-iphone-responsive {
		width: 100% !important;
		height: 150px !important;
	}
}



@media (max-width: 1300px) {
	.sectionHeight {
	  height: 120vh;
	}
  }
  @media (max-width: 2000px) {
	.sectionHeight {
	  height: 100vh;
	}
  }
